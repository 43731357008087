import React, { createContext, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { stripTime } from '../utils/Date';
import { getLocationHighRatingSpas } from '../utils';

const defaultValues = {
  isOverlayActive: false,
  setOverlayActive: () => {},

  recentlyViewed: null,
  isSpaInRecentlyViewed: () => {},
  addSpaToRecentlyViewed: () => {},
  addLocationHighRatingSpasToRecentlyViewed: () => {},

  wishlist: null,
  isSpaInWishlist: () => {},
  toggleSpaInWishlist: () => {},

  cartItemCount: 0,
  configureBooking: () => {},
  getVoucherThemes: () => {},
  getBookingConfiguration: () => {},
  addToCart: () => {},
  lineItemConfigured: () => {},
  getCart: () => {},
  getCartItem: () => {},
  refreshCartItem: () => {},
  getCartItemCount: () => {},
  updateCartItem: () => {},
  removeFromCart: () => {},
  updateItemQuantity: () => {},
  getDiscounts: () => {},
  addDiscount: () => {},
  removeDiscount: () => {},
  getVouchers: () => {},
  addVoucher: () => {},
  removeVoucher: () => {},
  removeExternalVoucher: () => {},

  getDeliveryMethods: () => {},
  postcodeLookup: () => {},
  getCheckout: () => {},
  saveCheckout: () => {},
  saveCustomerDetails: () => {},
  getCustomerDetails: () => {},

  createOrder: () => {},
  completeOrder: () => {},
  getOrder: () => {},

  getPayment: () => {},
  getPaypalContext: () => {},
  createPayment: () => {},
  createPaypalPayment: () => {},
  createPaypalContext: () => {},
  createPaypalContextPayment: () => {},
  validateApplePay: () => {},
  createApplePayPayment: () => {},
  paymentSuccess: () => {},
  paymentFailed: () => {},

  getVatReceipt: () => {},

  activateVoucher: () => {},

  loaderActive: false,
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive,
  );
  const [recentlyViewed, setRecentlyViewed] = useState(
    defaultValues.recentlyViewed,
  );
  const [wishlist, setWishlist] = useState(defaultValues.wishlist);
  const [cartItemCount, setCartItemCount] = useState(
    defaultValues.cartItemCount,
  );
  const [loaderActive, setLoaderActive] = useState(defaultValues.loaderActive);

  const isBrowser = typeof window !== 'undefined';
  const localStorageRecentlyViewedKey = `spaseekers:recently-viewed`;
  const localStorageWishlistKey = `spaseekers:wishlist`;
  const localStorageUserIdKey = `spaseekers:user-id`;

  useEffect(() => {
    initialiseRecentlyViewed();
    initialiseWishlist();
    initialiseCheckout();
  }, []);

  // Recently Viewed

  const initialiseRecentlyViewed = () => {
    try {
      const recentlyViewed =
        isBrowser &&
        JSON.parse(localStorage.getItem(localStorageRecentlyViewedKey))
          ? JSON.parse(localStorage.getItem(localStorageRecentlyViewedKey))
          : isBrowser
            ? []
            : null;

      if (recentlyViewed) {
        setRecentlyViewed(recentlyViewed);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isSpaInRecentlyViewed = spa =>
    recentlyViewed && recentlyViewed.find(item => item.spaId === spa.spaId);

  const updateRecentlyViewed = updatedRecentlyViewed => {
    setRecentlyViewed(updatedRecentlyViewed);

    localStorage.setItem(
      localStorageRecentlyViewedKey,
      JSON.stringify(updatedRecentlyViewed),
    );
  };

  const addSpaToRecentlyViewed = spa => {
    if (recentlyViewed && !isSpaInRecentlyViewed(spa)) {
      const updatedRecentlyViewed = [spa, ...recentlyViewed.slice(0, 3)];
      updateRecentlyViewed(updatedRecentlyViewed);
    }
  };

  const addLocationHighRatingSpasToRecentlyViewed = async locationID => {
    const locationHighRatingSpas = await getLocationHighRatingSpas(locationID);
    updateRecentlyViewed(locationHighRatingSpas);
  };

  // Wishlist

  const initialiseWishlist = () => {
    try {
      const wishlist =
        isBrowser && JSON.parse(localStorage.getItem(localStorageWishlistKey))
          ? JSON.parse(localStorage.getItem(localStorageWishlistKey))
          : isBrowser
            ? []
            : null;

      if (wishlist) {
        setWishlist(wishlist);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const isSpaInWishlist = spa =>
    wishlist && wishlist.find(item => item.spaId === spa.spaId);

  const updateWishlist = updatedWishlist => {
    setWishlist(updatedWishlist);

    localStorage.setItem(
      localStorageWishlistKey,
      JSON.stringify(updatedWishlist),
    );
  };

  const toggleSpaInWishlist = spa => {
    let updatedWishlist = [];

    if (isSpaInWishlist(spa)) {
      updatedWishlist = wishlist.filter(item => item.spaId !== spa.spaId);
    } else {
      updatedWishlist = [...wishlist, spa];
    }

    updateWishlist(updatedWishlist);
  };

  // Checkout API

  const initialiseCheckout = async () => {
    try {
      let cartItemCount = 0;

      const cartItemCountResult = await getCartItemCount();

      if (cartItemCountResult.ok() && cartItemCountResult.data) {
        cartItemCount = cartItemCountResult.data.itemCount || 0;
        setCartItemCount(cartItemCount);
      }

      const userId =
        isBrowser && localStorage.getItem(localStorageUserIdKey)
          ? localStorage.getItem(localStorageUserIdKey)
          : null;

      if (userId) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          user_id: userId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchWithRetry = (url, options, resolve, attempt, attempts) => {
    const wait = delay =>
      new Promise(resolve => {
        setTimeout(resolve, delay);
      });
    const retry = attempt => {
      console.warn(
        `Retrying call to ${url} due to previous failure (attempt ${attempt} of ${attempts})`,
      );
      return wait(Math.pow(2, attempt) * 50).then(() =>
        fetchWithRetry(url, options, resolve, attempt, attempts),
      );
    };
    const parse = response => {
      try {
        response
          .json()
          .then(json => {
            resolve({
              status: response.ok ? 'OK' : 'FAILED',
              data: json?.data,
              message: json?.error || json?.message,
              error: !response.ok,
              ok: () => !!response.ok,
            });
          })
          .catch(error => {
            // Likely an issue with a malformed body
            resolve({
              status: 'FAILED',
              message: error.message,
              error: true,
              ok: () => false,
            });
          });
      } catch (error) {
        // Hard error if promise catch is not called
        resolve({
          status: 'FAILED',
          message: error.message,
          error: true,
          ok: () => false,
        });
      }
    };

    options.headers.set('Attempt', attempt);

    return fetch(url, options)
      .then(response => {
        if (response.status === 500) {
          if (attempt < attempts) {
            // Server errors, let's retry if we can
            return retry(attempt + 1);
          }
        }
        parse(response);
      })
      .catch(error => {
        // Generally network errors would end up here, so let's retry.
        if (attempt >= attempts) {
          resolve({
            status: 'FAILED',
            message: error.message,
          });
        } else {
          return retry(attempt + 1);
        }
      });
  };

  const checkoutRequest = (
    method,
    endpoint,
    data,
    referer = '',
    attempts = 3,
  ) => {
    let requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');

    if (referer) {
      requestHeaders.append('Referer', `${referer}`);
    }

    const requestOptions = {
      method: method,
      headers: requestHeaders,
      body: Object.keys(data).length > 0 ? JSON.stringify(data) : undefined,
      credentials: 'include',
    };

    return new Promise(resolve => {
      fetchWithRetry(
        `${process.env.GATSBY_SPASEEKERS_CHECKOUT_API_URL}/${endpoint}`,
        requestOptions,
        resolve,
        1,
        attempts || 3,
      );
    });
  };

  const configureBooking = async ({
    spaId,
    packageId,
    numberOfRooms,
    numberOfGuests,
    arrivalDate,
  }) => {
    try {
      // Format data and send to request api
      const data = {
        spaId: spaId,
        packageId: packageId,
        numberOfRooms: numberOfRooms ? numberOfRooms : 0,
        numberOfGuests: numberOfGuests,
        arrivalDate: stripTime(arrivalDate),
      };

      const configuredBooking = await checkoutRequest(
        'POST',
        'v1/cart/configure-booking',
        data,
      );

      if (configuredBooking.error) {
        setLoaderActive(false);
        navigate('/500/', { replace: true });
      }

      return configuredBooking;
    } catch (e) {
      console.error(e);
    }
  };

  const getVoucherThemes = async () => {
    try {
      const themes = await checkoutRequest('GET', `v1/cart/themes`, {});

      return themes;
    } catch (e) {
      console.error(e);
    }
  };

  const getBookingConfiguration = async cartItemId => {
    try {
      const configuredBooking = await checkoutRequest(
        'GET',
        `v1/cart/configure-booking/${cartItemId}`,
        {},
      );

      return configuredBooking;
    } catch (e) {
      console.error(e);
    }
  };

  const addToCart = async cartItem => {
    try {
      const cart = await checkoutRequest(
        'POST',
        'v1/cart/item',
        cartItem,
        '',
        1,
      );

      if (cart && cart.ok()) {
        setCartItemCount(cartItemCount + cart.data.quantity);
      }

      return cart;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
      navigate('/500/', { replace: true });
    }
  };

  const lineItemConfigured = async cartItemId => {
    try {
      const cartItem = await checkoutRequest(
        'POST',
        `v1/cart/item/${cartItemId}/configured`,
        {},
      );

      return cartItem;
    } catch (e) {
      console.error(e);
    }
  };

  const getCart = async () => {
    try {
      const cart = await checkoutRequest('GET', 'v1/cart', {});
      if (cart.ok() && cart.data) {
        const cartItemCount = cart.data.itemCount || 0;
        setCartItemCount(cartItemCount);

        if (cartItemCount === 0) {
          navigate('/checkout/basket/');
        }
      }

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const getCartItem = async cartItemId => {
    try {
      const cart = await checkoutRequest(
        'GET',
        `v1/cart/item/${cartItemId}`,
        {},
      );

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const refreshCartItem = async cartItemId => {
    try {
      const cart = await checkoutRequest(
        'GET',
        `v1/cart/item/${cartItemId}/refresh`,
        {},
      );

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const getCartItemCount = async () => {
    try {
      const cart = await checkoutRequest('GET', `v1/cart/item-count`, {});

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const updateCartItem = async (cartItemId, data) => {
    try {
      const cart = await checkoutRequest(
        'PUT',
        `v1/cart/item/${cartItemId}`,
        data,
      );

      return cart;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const removeFromCart = async cartItemId => {
    try {
      const cart = await checkoutRequest(
        'DELETE',
        `v1/cart/item/${cartItemId}`,
        {},
      );

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const updateItemQuantity = async (cartItemId, quantity) => {
    try {
      const cart = await checkoutRequest(
        'PUT',
        `v1/cart/item/${cartItemId}/quantity`,
        { quantity: quantity },
      );

      return cart;
    } catch (e) {
      console.error(e);
    }
  };

  const getDiscounts = async () => {
    try {
      const discounts = await checkoutRequest('GET', `v1/cart/discounts`, {});

      return discounts;
    } catch (e) {
      console.error(e);
    }
  };

  const addDiscount = async code => {
    try {
      const discounts = await checkoutRequest(
        'POST',
        `v1/cart/discounts`,
        {
          code: code,
        },
        '',
        1,
      );

      return discounts;
    } catch (e) {
      console.error(e);
    }
  };

  const removeDiscount = async discountId => {
    try {
      const discounts = await checkoutRequest(
        'DELETE',
        `v1/cart/discounts/${discountId}`,
        {},
      );

      return discounts;
    } catch (e) {
      console.error(e);
    }
  };

  const getVouchers = async () => {
    try {
      const vouchers = await checkoutRequest(
        'GET',
        `v1/checkout/gift-options`,
        {},
      );

      return vouchers;
    } catch (e) {
      console.error(e);
    }
  };

  const addVoucher = async (urn, surname, value) => {
    try {
      const vouchers = await checkoutRequest(
        'POST',
        `v1/cart/vouchers`,
        {
          urn: urn,
          surname: surname,
          value: value,
        },
        '',
        1,
      );

      return vouchers;
    } catch (e) {
      console.error(e);
    }
  };

  const removeVoucher = async voucherId => {
    try {
      const vouchers = await checkoutRequest(
        'DELETE',
        `v1/cart/vouchers/${voucherId}`,
        {},
      );

      return vouchers;
    } catch (e) {
      console.error(e);
    }
  };

  const removeExternalVoucher = async voucherId => {
    try {
      const vouchers = await checkoutRequest(
        'DELETE',
        `v1/cart/vouchers/external/${voucherId}`,
        {},
      );

      return vouchers;
    } catch (e) {
      console.error(e);
    }
  };

  const getDeliveryMethods = async () => {
    try {
      const deliveryMethods = await checkoutRequest(
        'GET',
        `v1/checkout/delivery-methods`,
        {},
      );

      return deliveryMethods;
    } catch (e) {
      console.error(e);
    }
  };

  const postcodeLookup = async postcode => {
    try {
      // Remove any trailing spaces (mobile autofill)
      const lookup = await checkoutRequest(
        'GET',
        `v1/checkout/addresses/${encodeURI(postcode.trim())}`,
        {},
        process.env.GATSBY_SPASEEKERS_POSTCODE_LOOKUP_API_URL,
      );

      return lookup;
    } catch (e) {
      console.error(e);
    }
  };

  const getCheckout = async data => {
    try {
      const checkout = await checkoutRequest('GET', 'v1/checkout', {});

      if (
        checkout.data &&
        checkout.data.customerDetails &&
        checkout.data.customerDetails.tracking
      ) {
        if (
          !localStorage.getItem(localStorageUserIdKey) ||
          localStorage.getItem(localStorageUserIdKey) !==
            checkout.data.customerDetails.tracking.userId
        ) {
          localStorage.setItem(
            localStorageUserIdKey,
            checkout.data.customerDetails.tracking.userId,
          );

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            user_id: checkout.data.customerDetails.tracking.userId,
          });
        }
      }

      return checkout;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const saveCheckout = async data => {
    try {
      const checkout = await checkoutRequest('POST', 'v1/checkout', data);

      return checkout;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const saveCustomerDetails = async data => {
    try {
      const checkout = await checkoutRequest(
        'POST',
        'v1/checkout/customer-details',
        data,
      );

      return checkout;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const getCustomerDetails = async () => {
    try {
      const checkout = await checkoutRequest(
        'GET',
        'v1/checkout/customer-details',
        {},
      );

      return checkout;
    } catch (e) {
      console.error(e);
    }
  };

  const createOrder = async () => {
    try {
      const order = await checkoutRequest('POST', 'v1/order', {}, '', 1);

      return order;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const completeOrder = async () => {
    try {
      const result = await checkoutRequest('POST', 'v1/order/complete', {});

      return result;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const getOrder = async orderToken => {
    try {
      const order = await checkoutRequest('GET', `v1/order/${orderToken}`, {});

      return order;
    } catch (e) {
      console.error(e);
    }
  };

  const getPayment = async () => {
    try {
      const payment = await checkoutRequest('GET', 'v1/payment', {});

      return payment;
    } catch (e) {
      console.error(e);
    }
  };

  const getPaypalContext = async paymentContextId => {
    try {
      const context = await checkoutRequest(
        'GET',
        `v1/payment/paypal/context?paymentContextId=${paymentContextId}`,
        {},
        '',
        1,
      );

      return context;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const createPayment = async data => {
    try {
      const payment = await checkoutRequest('POST', 'v1/payment', data, '', 1);

      return payment;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const createPaypalPayment = async () => {
    try {
      const payment = await checkoutRequest(
        'POST',
        'v1/payment/paypal',
        {},
        '',
        1,
      );

      return payment;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const createPaypalContext = async () => {
    try {
      const context = await checkoutRequest(
        'POST',
        'v1/payment/paypal/context',
        {},
        '',
        1,
      );

      return context;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const createPaypalContextPayment = async paymentContextId => {
    try {
      const payment = await checkoutRequest(
        'POST',
        `v1/payment/paypal/payment?paymentContextId=${paymentContextId}`,
        {},
        '',
        1,
      );

      return payment;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const validateApplePay = async data => {
    try {
      const applePay = await checkoutRequest(
        'POST',
        'v1/payment/apple-pay/validate',
        data,
      );

      return applePay;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const createApplePayPayment = async data => {
    try {
      const applePay = await checkoutRequest(
        'POST',
        'v1/payment/apple-pay/payment',
        data,
      );

      return applePay;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  const paymentSuccess = async data => {
    try {
      const payment = await checkoutRequest('POST', 'v1/payment/success', data);

      return payment;
    } catch (e) {
      console.error(e);
    }
  };

  const paymentFailed = async data => {
    try {
      const payment = await checkoutRequest('POST', 'v1/payment/failure', data);

      return payment;
    } catch (e) {
      console.error(e);
    }
  };

  const getVatReceipt = async orderToken => {
    try {
      return `${process.env.GATSBY_SPASEEKERS_CHECKOUT_API_URL}/v1/order/${orderToken}/vat-receipt`;
    } catch (e) {
      console.error(e);
    }
  };

  const activateVoucher = async data => {
    try {
      const activate = await checkoutRequest(
        'POST',
        'v1/voucher/activate',
        data,
        '',
        1,
      );

      return activate;
    } catch (e) {
      setLoaderActive(false); // fallback to remove loader
      console.error(e);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        setOverlayActive,

        recentlyViewed,
        isSpaInRecentlyViewed,
        addSpaToRecentlyViewed,
        addLocationHighRatingSpasToRecentlyViewed,

        wishlist,
        isSpaInWishlist,
        toggleSpaInWishlist,

        cartItemCount,
        setCartItemCount,
        configureBooking,
        getBookingConfiguration,
        getVoucherThemes,
        addToCart,
        lineItemConfigured,
        getCart,
        getCartItem,
        refreshCartItem,
        getCartItemCount,
        updateCartItem,
        removeFromCart,
        updateItemQuantity,
        getDiscounts,
        addDiscount,
        removeDiscount,
        getVouchers,
        addVoucher,
        removeVoucher,
        removeExternalVoucher,

        getDeliveryMethods,
        postcodeLookup,
        getCheckout,
        saveCheckout,
        saveCustomerDetails,
        getCustomerDetails,

        createOrder,
        completeOrder,
        getOrder,

        getPayment,
        getPaypalContext,
        createPayment,
        createPaypalPayment,
        createPaypalContext,
        createPaypalContextPayment,
        validateApplePay,
        createApplePayPayment,
        paymentSuccess,
        paymentFailed,

        getVatReceipt,

        activateVoucher,

        loaderActive,
        setLoaderActive,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
